import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ auth, component: Component, ...rest }) => (
  <Route {...rest} >
    {auth ? Component : <Redirect to="/" />}
  </Route>
);

ProtectedRoute.propTypes = {
  auth: PropTypes.bool,
  component: PropTypes.element,
};

ProtectedRoute.defaultProps = {
  auth: false,
  component: null
};

export default ProtectedRoute;
