import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import { Toaster } from 'react-hot-toast';
import { AuthRequired } from './component/AuthRequired/AuthRequired';
import { AlreadyEnrolled } from './component/AlreadyEnrolled/AlreadyEnrolled';
import { NoProfileFound } from './component/NoProfileFound/NoProfileFound';
import { EnrolledSuccessfully } from './component/EnrolledSuccessfully/EnrolledSuccessfully';
import { VerifyCode } from './component/VerifyCode/VerifyCode';
import { Enrollment } from './component/Enrollment/Enrollment';
import { cookieConstants } from './constants/app-constants';
import { TimeoutModal } from './component/shared/TimeoutModal';
import { CustomLoader } from './component/shared/CustomLoader/CustomLoader';
import { RootContext } from './context/RootContext';
import ProtectedRoute from './ProtectedRoutes';

export const Routes = () => {
  const [timeoutValue, setTimeoutValue] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const rootProvider = useMemo(
    () => ({
      timeoutValue, showLoader, isLoggedIn, setTimeoutValue, setShowLoader, setIsLoggedIn
    }),
    [timeoutValue, setTimeoutValue, setShowLoader, showLoader, isLoggedIn, setIsLoggedIn]
  );

  const lockoutCookieConditionCheck = () => {
    const currentUnSuccessfulCountExpiryTime = Cookies.get(
      cookieConstants.UNSUCCESSFUL_COUNT_EXPIRY_TIME
    );
    const expiryTime = new Date(
      parseInt(currentUnSuccessfulCountExpiryTime, 10)
    ).getTime();
    const timeDifference = expiryTime - new Date().getTime();
    if (!Number.isNaN(timeDifference)) {
      setTimeoutValue(timeDifference);
    } else {
      setTimeoutValue(0);
    }
  };

  const isLoggedInConditionCheck = () => {
    const isLoggedInCookie = Cookies.get(cookieConstants.IS_LOGGED_IN);
    setIsLoggedIn(isLoggedInCookie === 'TRUE');
  };

  useEffect(() => {
    lockoutCookieConditionCheck();
    isLoggedInConditionCheck();
  }, []);

  return (
    <RootContext.Provider value={rootProvider}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/enroll" />
          </Route>
          <Route exact path="/enroll">
            <Enrollment />
          </Route>
          <ProtectedRoute exact path="/verify" component={<VerifyCode />} auth={isLoggedIn} />
          <ProtectedRoute exact path="/failed" component={<NoProfileFound />} auth={isLoggedIn} />
          <ProtectedRoute exact path="/enrolled" component={<EnrolledSuccessfully />} auth={isLoggedIn} />
          <ProtectedRoute exact path="/exist" component={<AlreadyEnrolled />} auth={isLoggedIn} />
          <ProtectedRoute exact path="/cannot-enroll" component={<AuthRequired />} auth={isLoggedIn} />
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
        <TimeoutModal />
        <CustomLoader />
        <Toaster position="bottom-center" toastOptions={{ duration: 2000 }} />
      </Router>
    </RootContext.Provider>
  );
};
