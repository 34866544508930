import PropTypes from 'prop-types';

import styles from './Modal.module.scss';

export const Modal = ({ children, contentStyles }) => (
    <div className={`popupBox ${styles.model}`}>
        <div style={contentStyles} className={`model-content ${styles.modelContents}`} id="modalContent">
            {children}
        </div>
    </div>
);

Modal.propTypes = {
    children: PropTypes.element,
    contentStyles: PropTypes.oneOfType([PropTypes.object])
};

Modal.defaultProps = {
    children: null,
    contentStyles: null
};
