import { FaCheck } from 'react-icons/fa';
import styles from './EnrolledSuccessfully.module.scss';

export const EnrolledSuccessfully = () => (
    <div className="contentWrapper">
        <div className="enrollmentTextTitle">
            You have successfully enrolled in SMS notifications!
        </div>
        <div className={styles.successIcon}>
            <FaCheck />
        </div>

        <div className="enrollmentTextContainer">
            Check your phone for a confirmation text. You may unenroll at any time
            by texting STOP to this number.
        </div>

        <div className="logoContainer">
            <img
                src="https://averhealth.com/wp-content/uploads/2017/11/full-logo.png"
                className="appLogo"
                alt="logo"
            />
        </div>
    </div>
);
