import React from 'react';
import ReactDOM from 'react-dom';
// import Footer from './component/Layout/Footer/Footer';
import WelcomeHeader from './component/Layout/Header/Header';
import reportWebVitals from './reportWebVitals';
import { Routes } from './routes';
import './styles/common.scss';

ReactDOM.render(
  <React.StrictMode>
    <div className="masterProfileLayout">
      <WelcomeHeader />
      <Routes />
    </div>
    {/* <Footer /> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
