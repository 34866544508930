/* eslint-disable operator-linebreak */
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Stack from '@mui/material/Stack';

import {
  useCallback,
  useReducer,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { FaKey, FaCalendar, FaPhone } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import styles from './Enrollment.module.scss';
import { cookieConstants } from '../../constants/app-constants';
import { findDonor, sendCodeForEnrollment } from '../../utils/api-provider';
import { Modal } from '../shared/Modal/Modal';
import { TnCstaticContent } from './TnCstaticContent';
import { convertSecToClockFormat } from '../../utils/date-utils';
import { RootContext } from '../../context/RootContext';

const formReducer = (state, event) => {
  if (event.reset) {
    return {
      dob: '',
      pin: '',
      cell: '',
      agree: false,
    };
  }

  return { ...state, [event.name]: event.value };
};

export const Enrollment = () => {
  const [formData, setFormData] = useReducer(formReducer, {});
  const history = useHistory();
  const [userDob, setUserDob] = useState();
  const [submitting, setSubmitting] = useState(false);
  const {
    timeoutValue, setTimeoutValue, setShowLoader, setIsLoggedIn
  } = useContext(RootContext);

  let interval;

  const updateCountCookie = () => {
    const currentUnSuccessfulCount =
      Cookies.get(cookieConstants.UNSUCCESSFUL_COUNT) || 1;
    const newCount = parseInt(currentUnSuccessfulCount, 10) + 1;
    const expires = new Date(
      new Date().getTime() + cookieConstants.COOKIE_ADDON_TIME
    );
    if (newCount > cookieConstants.MIN_UNSUCCESSFUL_COUNT) {
      Cookies.remove(cookieConstants.UNSUCCESSFUL_COUNT);
      Cookies.set(cookieConstants.UNSUCCESSFUL_COUNT, `${newCount}`, {
        expires,
      });
      Cookies.set(
        cookieConstants.UNSUCCESSFUL_COUNT_EXPIRY_TIME,
        `${new Date(expires).getTime()}`,
        { expires }
      );
      setTimeoutValue(cookieConstants.COOKIE_ADDON_TIME);
    } else {
      Cookies.set(cookieConstants.UNSUCCESSFUL_COUNT, `${newCount}`, { expires });
    }
  };
  const [showModal, setShowModal] = useState(false);

  const apiHandler = async (pin, dob, cell) => {
    try {
      setShowLoader(true);
      findDonor({ pin, dob })
        .then((resp) => {
          setIsLoggedIn(true);
          Cookies.set(cookieConstants.IS_LOGGED_IN, 'TRUE');
          if (resp.status === 200 && resp.data?.IsFound) {
            Cookies.remove(cookieConstants.UNSUCCESSFUL_COUNT);
            Cookies.remove(cookieConstants.UNSUCCESSFUL_COUNT_EXPIRY_TIME);
            const donorId = resp.data.Id;
            sendCodeForEnrollment({ donorId, cell })
              .then((res) => {
                if (res.status === 200) {
                  const donor = {};
                  donor.cell = cell;
                  donor.pin = pin;
                  donor.dob = dob;
                  donor.id = donorId;
                  setShowLoader(false);
                  history.push('/verify', { params: donor });
                }
              })
              .catch((error) => {
                setShowLoader(false);
                console.log(error);
                toast('Something went wrong');
              });
          } else {
            setShowLoader(false);
            updateCountCookie();
            history.push('/failed');
          }
        })
        .catch((err) => {
          setShowLoader(false);
          updateCountCookie();
          console.log(err);
          toast('Something went wrong');
        });
    } catch (err) {
      setShowLoader(false);
      updateCountCookie();
      if (err?.response?.status) {
        history.push('/failed');
      } else {
        console.log(err);
        toast('Something went wrong');
      }
    }
  };

  // begin
  const formik = useFormik({
    initialValues: {
      dob: '',
      pin: '',
      cell: '',
      agree: false,
    },
    validationSchema: yup.object({
      pin: yup
        .string()
        .trim()
        .matches(
          /* eslint-disable no-useless-escape */
          /^[0-9]+$/,
          'Enter a valid pin'
        )
        .required('This field is required*'),
      cell: yup
        .string()
        .trim()
        .matches(
          /* eslint-disable no-useless-escape */
          /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/,
          'Enter a valid phone number'
        )
        .required('This field is required*'),
      dob: yup
        .string()
        .matches(
          /* eslint-disable no-useless-escape */
          /(0[1-9]|1[012])[ \/.](0[1-9]|[12][0-9]|3[01])[ \/.](19|20)\d\d/,
          'Enter a valid DOB'
        )
        .required('This field is required*'),
      agree: yup.bool().oneOf([true], 'You must accept the term & condition'),
    }),
    onSubmit: (data) => {
      let phOnlyDigits = data.cell.replace(/\D/g, '');

      if (phOnlyDigits.length >= 11) {
        phOnlyDigits = phOnlyDigits.substring(1);
      }

      apiHandler(data.pin, data.dob, phOnlyDigits);
      if (false) {
        setFormData();
        setSubmitting();
      }
    },
  });
  // end

  const onDobChange = useCallback(
    (d) => {
      setUserDob(d);
      let fomattedDate = moment(d);
      if (fomattedDate.isValid()) {
        fomattedDate = fomattedDate.format('MM/DD/yyyy');
      } else {
        fomattedDate = '';
      }
      formik.setFieldValue('dob', fomattedDate);
      setFormData({
        name: 'dob',
        value: fomattedDate,
      });
    },
    [userDob]
  );

  const handleDocumentClick = (e) => {
    const modelContent = document.getElementById('modalContent');
    if (
      e.target.classList.contains('popupBox') &&
      !(
        modelContent &&
        modelContent.length &&
        modelContent[0].contains(e.target)
      )
    ) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', handleDocumentClick);
    setIsLoggedIn(false);
    Cookies.set(cookieConstants.IS_LOGGED_IN, 'FALSE');
    return () => {
      document.body.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (timeoutValue > 0) {
      setTimeout(() => {
        if (interval) {
          clearInterval(interval);
        }
        setTimeoutValue(0);
      }, timeoutValue);
      interval = setInterval(() => {
        setTimeoutValue((prevValue) => prevValue - 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timeoutValue]);

  return (
    <>
      {showModal && (
        <Modal contentStyles={{ width: '90%' }}>
          <TnCstaticContent showModal={showModal} setShowModal={setShowModal} />
        </Modal>
      )}
      <form onSubmit={formik.handleSubmit} className={styles.enrollForm}>
        <div className={styles.enrollFormContainer}>
          <h1 className={styles.enrollTitle}>
            Fill out the form below to enroll in SMS notifications:
          </h1>

          <div className={styles.enrollInputWrapper}>
            <div className={styles.enrollInputIconContainer}>
              <span className={styles.leftSpace} />
              <fieldset>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      inputFormat="MM/dd/yyyy"
                      value={
                        formData?.dob && formData?.dob !== ''
                          ? formData?.dob
                          : ''
                      }
                      onChange={onDobChange}
                      InputAdornmentProps={{ position: 'start' }}
                      components={{
                        OpenPickerIcon: FaCalendar,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: 'Birthdate mm/dd/yyyy',
                          }}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </fieldset>
            </div>
            {formik.touched.dob && formik.errors.dob && (
              <div className={styles.inputErrMsg}>{formik.errors.dob}</div>
            )}
          </div>
          <div className={styles.enrollInputWrapper}>
            <div className={styles.enrollInputIconContainer}>
              <span className={styles.leftSpace} />
              <FaKey />
              <fieldset disabled={submitting}>
                <label htmlFor="pin">
                  <input
                    name="pin"
                    className={styles.enrollInputs}
                    placeholder="PIN Number"
                    type="password"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    onChange={formik.handleChange}
                    value={formik.values.pin || ''}
                  />
                </label>
              </fieldset>
            </div>
            {formik.touched.pin && formik.errors.pin && (
              <div className={styles.inputErrMsg}>{formik.errors.pin}</div>
            )}
          </div>
          <div className={styles.enrollInputWrapper}>
            <div className={styles.enrollInputIconContainer}>
              <span className={styles.leftSpace} />
              <FaPhone />
              <fieldset disabled={submitting}>
                <label htmlFor="cell">
                  <input
                    name="cell"
                    className={`${styles.enrollInputs} ${styles.loginDate}`}
                    placeholder="Phone Number"
                    type="tel"
                    onChange={formik.handleChange}
                    value={formik.values.cell || ''}
                  />
                </label>
              </fieldset>
            </div>
            {formik.touched.cell && formik.errors.cell && (
              <div className={styles.inputErrMsg}>{formik.errors.cell}</div>
            )}
          </div>
          <div className={styles.spacerDiv} />

          <div className={styles.timeOutMessage}>
            {timeoutValue !== 0 && (
              <p>
                Try enrolling after{' '}
                <span className={styles.timerCount}>
                  {convertSecToClockFormat(Math.floor(timeoutValue / 1000))}
                </span>
              </p>
            )}
          </div>

          <div className={styles.enrollAgreeCheckboxContainer}>
            <fieldset disabled={submitting}>
              <label htmlFor="agree">
                <input
                  className={styles.enrollAgreeCheckbox}
                  type="checkbox"
                  name="agree"
                  disabled={formData.cell === ''}
                  onChange={formik.handleChange}
                  checked={formik.values.agree || false}
                />
              </label>
            </fieldset>
            <h3 className={styles.enrollTermsDesc}>
              I have read and agree to the&nbsp;
              <button
                type="button"
                onClick={() => setShowModal(true)}
                className={styles.loginTermsLink}
              >
                terms and conditions.
              </button>
            </h3>
          </div>

          <div className={styles.spacerDiv} />

          <div className={styles.enrollContainer}>
            <button
              className={styles.enrollButton}
              disabled={
                formik.errors.dob ||
                formik.errors.cell ||
                formik.errors.pin ||
                formik.values.agree === false ||
                timeoutValue !== 0
              }
              type="submit"
            >
              Enroll in SMS Notifications
            </button>
          </div>
          <div className="logoContainer">
            <img
              src="https://averhealth.com/wp-content/uploads/2017/11/full-logo.png"
              className="appLogo"
              alt="logo"
            />
          </div>
        </div>
      </form>
    </>
  );
};
