import axios from 'axios';
import base64 from 'base-64';
import utf8 from 'utf8';
import {
  API_CONSTANTS,
  API_ENDPOINT,
  BASE_URL,
} from '../constants/api-endpoint';
import { isDebug } from './common-methods';

const string = `${process.env.REACT_APP_SMS_USERNAME}:${process.env.REACT_APP_SMS_PASSWORD}`;
const bytes = utf8.encode(string);
const credidential = base64.encode(bytes);

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Basic ${credidential}`,
  },
  timeout: 10 * 1000, // 10 seconds
});

export const findDonor = async ({ pin, dob }) => {
  const queryString = `PinCode=${encodeURIComponent(
    pin
  )}&DateOfBirth=${encodeURIComponent(dob)}`;
  const url = `${isDebug() ? API_CONSTANTS.WEB_API : BASE_URL.WEB_API}${
    API_ENDPOINT.FIND_DONOR
  }?${queryString}`;
  try {
    const response = await instance.get(url);
    return response;
  } catch (error) {
    return error;
  }
};

export const sendCodeForEnrollment = async ({ donorId, cell }) => {
  const queryStringSendCode = `DonorId=${encodeURIComponent(
    donorId
  )}&PhoneNumber=${encodeURIComponent(cell)}`;
  const url = `${isDebug() ? API_CONSTANTS.WEB_API : BASE_URL.WEB_API}${
    API_ENDPOINT.SEND_CODE_FOR_ENROLLMENT
  }?${queryStringSendCode}`;
  try {
    const response = await instance.post(url);
    return response;
  } catch (error) {
    return error;
  }
};

export const verifyCodeAPI = async ({ donorId, otp }) => {
  const queryStringVerfiy = `DonorId=${encodeURIComponent(
    donorId
  )}&Code=${encodeURIComponent(otp)}`;
  const url = `${isDebug() ? API_CONSTANTS.WEB_API : BASE_URL.WEB_API}${
    API_ENDPOINT.VERIFY_CODE
  }?${queryStringVerfiy}`;
  try {
    const response = await instance.post(url);
    return response;
  } catch (error) {
    return error;
  }
};
