export const BASE_URL = {
  MOBILE_API: 'https://devmobileapi.averhealth.com',
  WEB_API: process.env.REACT_APP_WEB_BASE_URL,
};
export const API_CONSTANTS = {
  MOBILE_API: '/api-mobile',
  WEB_API: '/api-web',
};
export const API_ENDPOINT = {
  VERIFY_CODE: '/api/Mobile/VerifyCode',
  SEND_CODE: '/Account/SendCode',
  SEND_CODE_FOR_ENROLLMENT: '/api/Mobile/SendCodeForEnroll',
  FIND_DONOR: '/api/Mobile/FindDonor',
  POST_DONOR: '/api/Donor',
  ENROLL: '/api/Donor/Enroll',
};
