import { useHistory } from 'react-router-dom';

import { FcAssistant } from 'react-icons/fc';
import styles from './NoProfileFound.module.scss';

export const NoProfileFound = () => {
    const history = useHistory();
    return (
        <div className="contentWrapper">
            <div className="enrollmentTextTitle">
                We can&apos;t seem to find your profile..
            </div>
            <div className={styles.failIcon}>
                <FcAssistant />
            </div>

            <div className="enrollmentTextContainer">
                Please verify the information you entered is correct.
                If correct, an Averheath Patient Care Technician can verify your information setup in Aversys.
            </div>

            <div className="logoContainer">
                <button className={styles.failedLoginRetry} onClick={() => history.replace('/')} type="button">Retry</button>
            </div>
        </div>
    );
};
