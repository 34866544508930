import { useContext } from 'react';
import { BiLoaderAlt } from 'react-icons/bi';

import { RootContext } from '../../../context/RootContext';
import styles from './CustomLoader.module.scss';

export const CustomLoader = () => {
  const { showLoader } = useContext(RootContext);
  if (showLoader) {
    document.body.classList.add('popUpOpen');
  } else {
    document.body.classList.remove('popUpOpen');
  }
  if (!showLoader) return null;
  return (
    <div className={styles.loaderBackdrop}>
      <div className={`${styles.loaderSpin} ${styles.loader}`}>
        <BiLoaderAlt color="orange" size={48} />
      </div>
    </div>
  );
};

CustomLoader.propTypes = {};

CustomLoader.defaultProps = {};
