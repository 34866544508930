import PropTypes from 'prop-types';

import styles from './Enrollment.module.scss';

export const TnCstaticContent = ({ setShowModal }) => (
  <div className={styles.modalWrapper}>
    <div className={styles.modalHeader}>
      <h2>Terms and Conditions</h2>
    </div>
    <div className={styles.modalBody}>
      <h3>User Consent</h3>
      <p>
        Consent to Unencrypted Electronic Communications - I hereby consent to
        and request that Averhealth send notifications by short messaging
        service (SMS) that includes, but is not limited to, testing
        notifications, treatment communications, disclosures, or any other
        communications as determined by Averhealth. I understand that Averhealth
        has no control as to who may have access to the telephone number and I
        designate and that the notifications Averhealth sends may contain
        personal health information (PHI) that is protected under the Health
        Insurance Portability &amp; Accountability Act. With that knowledge, I
        am authorizing and consenting to the distribution of that information.
      </p>
      <h3>General Terms and Conditions</h3>
      <h5>Introduction</h5>
      <p>
        These Website Standard Terms And Conditions (these &ldquo;Terms&rdquo;
        or these &ldquo;Website Standard Terms And Conditions&rdquo;) contained
        herein on this webpage, shall govern your use of this website, including
        all pages within this website (collectively referred to herein below as
        this &ldquo;Website&rdquo;). These Terms apply in full force and effect
        to your use of this Website and by using this Website, you expressly
        accept all terms and conditions contained herein in full. You must not
        use this Website, if you have any objection to any of these Website
        Standard Terms And Conditions.
      </p>
      <h5>Intellectual Property Rights</h5>
      <p>
        Under these Terms, Avertest, LLC and/or its licensors own all rights to
        the intellectual property and material contained in this Website, and
        all such rights are reserved. You are granted a limited license only,
        subject to the restrictions provided in these Terms, for purposes of
        viewing the material contained on this Website,
      </p>
      <h5>Restrictions</h5>
      <p>
        You are expressly and emphatically restricted from all of the following:
      </p>
      <p>1.publishing any Website material in any media;</p>
      <p>
        2.selling, sublicensing and/or otherwise commercializing any Website
        material;
      </p>
      <p>3.publicly performing and/or showing any Website material;</p>
      <p>
        4.using this Website in any way that is, or may be, damaging to this
        Website;
      </p>
      <p>
        5.using this Website in any way that impacts user access to this
        Website;
      </p>
      <p>
        6.using this Website contrary to applicable laws and regulations, or in
        a way that causes, or may cause, harm to the Website, or to any person
        or business entity
      </p>
      <p>
        7.engaging in any data mining, data harvesting, data extracting or any
        other similar activity in relation to this Website, or while using this
        Website.
      </p>
      <p>8.using this Website to engage in any advertising or marketing;</p>
      <p>
        Certain areas of this Website are restricted from access by you and
        Avertest, LLC may further restrict access by you to any areas of this
        Website, at any time, in its sole and absolute discretion. Any user ID
        and password you may have for this Website are confidential and you must
        maintain confidentiality of such information.
      </p>
      <h5>No Warranties</h5>
      <p>
        This Website is provided &ldquo;as is,&rdquo; with all faults, and
        Avertest, LLC makes no express or implied representations or warranties,
        of any kind related to this Website or the materials contained on this
        Website. Additionally, nothing contained on this Website shall be
        construed as providing consult or advice to you.
      </p>
      <h5>Limitation of Liability</h5>
      <p>
        In no event shall Avertest, LLC, nor any of its officers, directors and
        employees, be liable to you for anything arising out of or in any way
        connected with your use of this Website, whether such liability is under
        contract, tort or otherwise, and Avertest, LLC, including its officers,
        directors and employees shall not be liable for any indirect,
        consequential or special liability arising out of or in any way related
        to your use of this Website.
      </p>
      <h5>Indemnification</h5>
      <p>
        You hereby indemnify to the fullest extent Avertest, LLC from and
        against any and all liabilities, costs, demands, causes of action,
        damages and expenses (including reasonable attorney&rsquo;s fees)
        arising out of or in any way related to your breach of any of the
        provisions of these Terms.
      </p>
      <h5>Severability</h5>
      <p>
        If any provision of these Terms is found to be unenforceable or invalid
        under any applicable law, such unenforceability or invalidity shall not
        render these Terms unenforceable or invalid as a whole, and such
        provisions shall be deleted without affecting the remaining provisions
        herein.
      </p>
      <h5>Variation of Terms</h5>
      <p>
        Avertest, LLC is permitted to revise these Terms at any time as it sees
        fit, and by using this Website you are expected to review such Terms on
        a regular basis to ensure you understand all terms and conditions
        governing use of this Website.
      </p>
      <h5>Assignment</h5>
      <p>
        Avertest, LLC shall be permitted to assign, transfer, and subcontract
        its rights and/or obligations under these Terms without any notification
        or consent required. However, you shall not be permitted to assign,
        transfer, or subcontract any of your rights and/or obligations under
        these Terms.
      </p>
      <h5>Entire Agreement</h5>
      <p>
        These Terms, including any legal notices and disclaimers contained on
        this Website, constitute the entire agreement between Avertest, LLC and
        you in relation to your use of this Website, and supersede all prior
        agreements and understandings with respect to the same.
      </p>
      <h5>Governing Law and Jurisdiction</h5>
      <p>
        These Terms will be governed by and construed in accordance with the
        laws of the State of California, and you submit to the non-exclusive
        jurisdiction of the state and federal courts located in California for
        the resolution of any disputes.
      </p>
      <h3>Privacy Policy</h3>
      <p>
        <b>Last updated: April 21, 2022</b>
      </p>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>
      <p>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>
      <h3>Interpretation and Definitions</h3>
      <h5>Interpretation</h5>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h5>Definitions</h5>
      <p>
        <b>For the purposes of this Privacy Policy:</b>
      </p>
      <p>
        Account means a unique account created for You to access our Service or
        parts of our Service.
      </p>
      <p>
        Business, for the purpose of the CCPA (California Consumer Privacy Act),
        refers to the Company as the legal entity that collects Consumers&apos;
        personal information and determines the purposes and means of the
        processing of Consumers&apos; personal information, or on behalf of
        which such information is collected and that alone, or jointly with
        others, determines the purposes and means of the processing of
        consumers&apos; personal information, that does business in the State of
        California.
      </p>
      <p>
        Company (referred to as either &quot;the Company&quot;, &quot;We&quot;,
        &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to AverTest,
        LCC, 2916 W. Marshall St., Suite A Richmond, VA 23230.
      </p>
      <p>
        Consumer, for the purpose of the CCPA (California Consumer Privacy Act),
        means a natural person who is a California resident. A resident, as
        defined in the law, includes (1) every individual who is in the USA for
        other than a temporary or transitory purpose, and (2) every individual
        who is domiciled in the USA who is outside the USA for a temporary or
        transitory purpose.
      </p>
      <p>
        Cookies are small files that are placed on Your computer, mobile device
        or any other device by a website, containing the details of Your
        browsing history on that website among its many uses.
      </p>
      <p>Country refers to: Virginia, United States</p>
      <p>
        Device means any device that can access the Service such as a computer,
        a cellphone or a digital tablet.
      </p>
      <p>
        Do Not Track (DNT) is a concept that has been promoted by US regulatory
        authorities, in particular the U.S. Federal Trade Commission (FTC), for
        the Internet industry to develop and implement a mechanism for allowing
        internet users to control the tracking of their online activities across
        websites.
      </p>
      <p>
        Personal Data is any information that relates to an identified or
        identifiable individual.
      </p>
      <p>
        For the purposes of the CCPA, Personal Data means any information that
        identifies, relates to, describes or is capable of being associated
        with, or could reasonably be linked, directly or indirectly, with You.
      </p>
      <p>
        Sale, for the purpose of the CCPA (California Consumer Privacy Act),
        means selling, renting, releasing, disclosing, disseminating, making
        available, transferring, or otherwise communicating orally, in writing,
        or by electronic or other means, a Consumer&apos;s personal information
        to another business or a third party for monetary or other valuable
        consideration.
      </p>
      <h5>Service refers to the Website.</h5>
      <p>
        Service Provider means any natural or legal person who processes the
        data on behalf of the Company. It refers to third-party companies or
        individuals employed by the Company to facilitate the Service, to
        provide the Service on behalf of the Company, to perform services
        related to the Service or to assist the Company in analyzing how the
        Service is used.
      </p>
      <p>
        Usage Data refers to data collected automatically, either generated by
        the use of the Service or from the Service infrastructure itself (for
        example, the duration of a page visit).
      </p>
      <p>
        Website refers to SMS Enrollment Form, accessible from{' '}
        <a
          href="https://www.avertext.averhealth.com"
          target="_blank"
          rel="noreferrer"
        >
          https://www.avertext.averhealth.com
        </a>
      </p>
      <p>
        You means the individual accessing or using the Service, or the company,
        or other legal entity on behalf of which such individual is accessing or
        using the Service, as applicable.
      </p>
      <h5>Collecting and Using Your Personal Data</h5>
      <h5>Types of Data Collected</h5>
      <h5>Personal Data</h5>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>
      <p>1.First name and last name</p>
      <p>2.Phone number</p>
      <p>3.Usage Data</p>
      <h5>Usage Data</h5>
      <p>Usage Data is collected automatically when using the Service.</p>
      <p>
        Usage Data may include information such as Your Device&apos;s Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </p>
      <p>
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </p>
      <p>Tracking Technologies and Cookies</p>
      <p>
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:
      </p>
      <p>
        Cookies or Browser Cookies. A cookie is a small file placed on Your
        Device. You can instruct Your browser to refuse all Cookies or to
        indicate when a Cookie is being sent. However, if You do not accept
        Cookies, You may not be able to use some parts of our Service. Unless
        you have adjusted Your browser setting so that it will refuse Cookies,
        our Service may use Cookies.
      </p>
      <p>
        Flash Cookies. Certain features of our Service may use local stored
        objects (or Flash Cookies) to collect and store information about Your
        preferences or Your activity on our Service. Flash Cookies are not
        managed by the same browser settings as those used for Browser Cookies.
        For more information on how You can delete Flash Cookies, please read
        &quot;Where can I change the settings for disabling, or deleting local
        shared objects?&quot; available at{' '}
        <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_">
          https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html
        </a>
      </p>
      <p>
        Web Beacons. Certain sections of our Service and our emails may contain
        small electronic files known as web beacons (also referred to as clear
        gifs, pixel tags, and single-pixel gifs) that permit the Company, for
        example, to count users who have visited those pages or opened an email
        and for other related website statistics (for example, recording the
        popularity of a certain section and verifying system and server
        integrity).
      </p>
      <p>
        Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
        Persistent Cookies remain on Your personal computer or mobile device
        when You go offline, while Session Cookies are deleted as soon as You
        close Your web browser. You can learn more about cookies here: Cookies
        by TermsFeed Generator.
      </p>
      <p>
        We use both Session and Persistent Cookies for the purposes set out
        below:
      </p>
      <p>Necessary / Essential Cookies</p>
      <p>Type: Session Cookies</p>
      <p>Administered by: Us</p>
      <p>
        Purpose: These Cookies are essential to provide You with services
        available through the Website and to enable You to use some of its
        features. They help to authenticate users and prevent fraudulent use of
        user accounts. Without these Cookies, the services that You have asked
        for cannot be provided, and We only use these Cookies to provide You
        with those services.
      </p>
      <p>Cookies Policy / Notice Acceptance Cookies</p>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Us</p>
      <p>
        Purpose: These Cookies identify if users have accepted the use of
        cookies on the Website.
      </p>
      <p>Functionality Cookies</p>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Us</p>
      <p>
        Purpose: These Cookies allow us to remember choices You make when You
        use the Website, such as remembering your login details or language
        preference. The purpose of these Cookies is to provide You with a more
        personal experience and to avoid You having to re-enter your preferences
        every time You use the Website.
      </p>
      <p>Tracking and Performance Cookies</p>
      <p>Type: Persistent Cookies</p>
      <p>Administered by: Third-Parties</p>
      <p>
        Purpose: These Cookies are used to track information about traffic to
        the Website and how users use the Website. The information gathered via
        these Cookies may directly or indirectly identify you as an individual
        visitor. This is because the information collected is typically linked
        to a pseudonymous identifier associated with the device you use to
        access the Website. We may also use these Cookies to test new pages,
        features or new functionality of the Website to see how our users react
        to them.
      </p>
      <p>
        For more information about the cookies we use and your choices regarding
        cookies, please visit our Cookies Policy or the Cookies section of our
        Privacy Policy.
      </p>
      <p>Use of Your Personal Data</p>
      <p>The Company may use Personal Data for the following purposes:</p>
      <p>
        To provide and maintain our Service, including to monitor the usage of
        our Service.
      </p>
      <p>
        To manage Your Account: to manage Your registration as a user of the
        Service. The Personal Data You provide can give You access to different
        functionalities of the Service that are available to You as a registered
        user.
      </p>
      <p>
        For the performance of a contract: the development, compliance and
        undertaking of the purchase contract for the products, items or services
        You have purchased or of any other contract with Us through the Service.
      </p>
      <p>
        To contact You: To contact You by email, telephone calls, SMS, or other
        equivalent forms of electronic communication, such as a mobile
        application&apos;s push notifications regarding updates or informative
        communications related to the functionalities, products or contracted
        services, including the security updates, when necessary or reasonable
        for their implementation.
      </p>
      <p>
        To provide You with news, special offers and general information about
        other goods, services and events which we offer that are similar to
        those that you have already purchased or enquired about unless You have
        opted not to receive such information.
      </p>
      <p>To manage Your requests: To attend and manage Your requests to Us.</p>
      <p>
        For business transfers: We may use Your information to evaluate or
        conduct a merger, divestiture, restructuring, reorganization,
        dissolution, or other sale or transfer of some or all of Our assets,
        whether as a going concern or as part of bankruptcy, liquidation, or
        similar proceeding, in which Personal Data held by Us about our Service
        users is among the assets transferred.
      </p>
      <p>
        For other purposes: We may use Your information for other purposes, such
        as data analysis, identifying usage trends, determining the
        effectiveness of our promotional campaigns and to evaluate and improve
        our Service, products, services, marketing and your experience.
      </p>
      <p>We may share Your personal information in the following situations:</p>
      <p>
        With Service Providers: We may share Your personal information with
        Service Providers to monitor and analyze the use of our Service, to
        contact You.
      </p>
      <p>
        For business transfers: We may share or transfer Your personal
        information in connection with, or during negotiations of, any merger,
        sale of Company assets, financing, or acquisition of all or a portion of
        Our business to another company.
      </p>
      <p>
        With Affiliates: We may share Your information with Our affiliates, in
        which case we will require those affiliates to honor this Privacy
        Policy. Affiliates include Our parent company and any other
        subsidiaries, joint venture partners or other companies that We control
        or that are under common control with Us.
      </p>
      <p>
        With business partners: We may share Your information with Our business
        partners to offer You certain products, services or promotions.
      </p>
      <p>
        With other users: when You share personal information or otherwise
        interact in the public areas with other users, such information may be
        viewed by all users and may be publicly distributed outside.
      </p>
      <p>
        With Your consent: We may disclose Your personal information for any
        other purpose with Your consent.
      </p>
      <h5>Retention of Your Personal Data</h5>
      <p>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </p>
      <p>
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </p>
      <h5>Transfer of Your Personal Data</h5>
      <p>
        Your information, including Personal Data, is processed at the
        Company&apos;s operating offices and in any other places where the
        parties involved in the processing are located. It means that this
        information may be transferred to &mdash; and maintained on &mdash;
        computers located outside of Your state, province, country or other
        governmental jurisdiction where the data protection laws may differ than
        those from Your jurisdiction.
      </p>
      <p>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </p>
      <p>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </p>
      <h3>Disclosure of Your Personal Data</h3>
      <h5>Business Transactions</h5>
      <p>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </p>
      <h5>Law enforcement</h5>
      <p>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </p>
      <h5>Other legal requirements</h5>
      <p>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </p>
      <p>Comply with a legal obligation</p>
      <p>Protect and defend the rights or property of the Company</p>
      <p>
        Prevent or investigate possible wrongdoing in connection with the
        Service
      </p>
      <p>Protect the personal safety of Users of the Service or the public</p>
      <p>Protect against legal liability</p>
      <p>Security of Your Personal Data</p>
      <p>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </p>
      <p>Detailed Information on the Processing of Your Personal Data</p>
      <p>
        The Service Providers We use may have access to Your Personal Data.
        These third-party vendors collect, store, use, process and transfer
        information about Your activity on Our Service in accordance with their
        Privacy Policies.
      </p>
      <h5>Analytics</h5>
      <p>
        We may use third-party Service providers to monitor and analyze the use
        of our Service.
      </p>
      <h5>Google Analytics</h5>
      <p>
        Google Analytics is a web analytics service offered by Google that
        tracks and reports website traffic. Google uses the data collected to
        track and monitor the use of our Service. This data is shared with other
        Google services. Google may use the collected data to contextualize and
        personalize the ads of its own advertising network.
      </p>
      <p>
        You can opt-out of having made your activity on the Service available to
        Google Analytics by installing the Google Analytics opt-out browser
        add-on. The add-on prevents the Google Analytics JavaScript (ga.js,
        analytics.js and dc.js) from sharing information with Google Analytics
        about visits activity.
      </p>
      <p>
        For more information on the privacy practices of Google, please visit
        the Google Privacy &amp; Terms web page:{' '}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          https://policies.google.com/privacy
        </a>
      </p>
      <h5>CCPA Privacy</h5>
      <p>
        This privacy notice section for California residents supplements the
        information contained in Our Privacy Policy and it applies solely to all
        visitors, users, and others who reside in the State of California.
      </p>
      <p>Categories of Personal Information Collected</p>
      <p>
        We collect information that identifies, relates to, describes,
        references, is capable of being associated with, or could reasonably be
        linked, directly or indirectly, with a particular Consumer or Device.
        The following is a list of categories of personal information which we
        may collect or may have been collected from California residents within
        the last twelve (12) months.
      </p>
      <p>
        Please note that the categories and examples provided in the list below
        are those defined in the CCPA. This does not mean that all examples of
        that category of personal information were in fact collected by Us, but
        reflects our good faith belief to the best of our knowledge that some of
        that information from the applicable category may be and may have been
        collected. For example, certain categories of personal information would
        only be collected if You provided such personal information directly to
        Us.
      </p>
      <p>Category A: Identifiers.</p>
      <p>
        Examples: A real name, alias, postal address, unique personal
        identifier, online identifier, Internet Protocol address, email address,
        account name, driver&apos;s license number, passport number, or other
        similar identifiers.
      </p>
      <p>Collected: Yes.</p>
      <p>
        Category B: Personal information categories listed in the California
        Customer Records statute (Cal. Civ. Code &sect; 1798.80(e)).
      </p>
      <p>
        Examples: A name, signature, Social Security number, physical
        characteristics or description, address, telephone number, passport
        number, driver&apos;s license or state identification card number,
        insurance policy number, education, employment, employment history, bank
        account number, credit card number, debit card number, or any other
        financial information, medical information, or health insurance
        information. Some personal information included in this category may
        overlap with other categories.
      </p>
      <p>Collected: Yes.</p>
      <p>
        Category C: Protected classification characteristics under California or
        federal law.
      </p>
      <p>
        Examples: Age (40 years or older), race, color, ancestry, national
        origin, citizenship, religion or creed, marital status, medical
        condition, physical or mental disability, sex (including gender, gender
        identity, gender expression, pregnancy or childbirth and related medical
        conditions), sexual orientation, veteran or military status, genetic
        information (including familial genetic information).
      </p>
      <p>Collected: No.</p>
      <p>Category D: Commercial information.</p>
      <p>
        Examples: Records and history of products or services purchased or
        considered.
      </p>
      <p>Collected: No.</p>
      <p>Category E: Biometric information.</p>
      <p>
        Examples: Genetic, physiological, behavioral, and biological
        characteristics, or activity patterns used to extract a template or
        other identifier or identifying information, such as, fingerprints,
        faceprints, and voiceprints, iris or retina scans, keystroke, gait, or
        other physical patterns, and sleep, health, or exercise data.
      </p>
      <p>Collected: No.</p>
      <p>Category F: Internet or other similar network activity.</p>
      <p>Examples: Interaction with our Service or advertisement.</p>
      <p>Collected: Yes.</p>
      <p>Category G: Geolocation data.</p>
      <p>Examples: Approximate physical location.</p>
      <p>Collected: No.</p>
      <p>Category H: Sensory data.</p>
      <p>
        Examples: Audio, electronic, visual, thermal, olfactory, or similar
        information.
      </p>
      <p>Collected: No.</p>
      <p>Category I: Professional or employment-related information.</p>
      <p>Examples: Current or past job history or performance evaluations.</p>
      <p>Collected: No.</p>
      <p>
        Category J: Non-public education information (per the Family Educational
        Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).
      </p>
      <p>
        Examples: Education records directly related to a student maintained by
        an educational institution or party acting on its behalf, such as
        grades, transcripts, class lists, student schedules, student
        identification codes, student financial information, or student
        disciplinary records.
      </p>
      <p>Collected: No.</p>
      <p>Category K: Inferences drawn from other personal information.</p>
      <p>
        Examples: Profile reflecting a person&apos;s preferences,
        characteristics, psychological trends, predispositions, behavior,
        attitudes, intelligence, abilities, and aptitudes.
      </p>
      <p>Collected: No.</p>
      <p>Under CCPA, personal information does not include:</p>
      <p>Publicly available information from government records</p>
      <p>Deidentified or aggregated consumer information</p>
      <p>Information excluded from the CCPA&apos;s scope, such as:</p>
      <p>
        Health or medical information covered by the Health Insurance
        Portability and Accountability Act of 1996 (HIPAA) and the California
        Confidentiality of Medical Information Act (CMIA) or clinical trial data
      </p>
      <p>
        Personal Information covered by certain sector-specific privacy laws,
        including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley
        Act (GLBA) or California Financial Information Privacy Act (FIPA), and
        the Driver&apos;s Privacy Protection Act of 1994
      </p>
      <p>Sources of Personal Information</p>
      <p>
        We obtain the categories of personal information listed above from the
        following categories of sources:
      </p>
      <p>
        Directly from You. For example, from the forms You complete on our
        Service, preferences You express or provide through our Service.
      </p>
      <p>
        Indirectly from You. For example, from observing Your activity on our
        Service.
      </p>
      <p>
        Automatically from You. For example, through cookies We or our Service
        Providers set on Your Device as You navigate through our Service.
      </p>
      <p>
        From Service Providers. For example, third-party vendors to monitor and
        analyze the use of our Service, or other third-party vendors that We use
        to provide the Service to You.
      </p>
      <p>
        Use of Personal Information for Business Purposes or Commercial Purposes
      </p>
      <p>
        We may use or disclose personal information We collect for
        &quot;business purposes&quot; or &quot;commercial purposes&quot; (as
        defined under the CCPA), which may include the following examples:
      </p>
      <p>To operate our Service and provide You with our Service.</p>
      <p>
        To provide You with support and to respond to Your inquiries, including
        to investigate and address Your concerns and monitor and improve our
        Service.
      </p>
      <p>
        To fulfill or meet the reason You provided the information. For example,
        if You share Your contact information to ask a question about our
        Service, We will use that personal information to respond to Your
        inquiry.
      </p>
      <p>
        To respond to law enforcement requests and as required by applicable
        law, court order, or governmental regulations.
      </p>
      <p>
        As described to You when collecting Your personal information or as
        otherwise set forth in the CCPA.
      </p>
      <p>For internal administrative and auditing purposes.</p>
      <p>
        To detect security incidents and protect against malicious, deceptive,
        fraudulent or illegal activity, including, when necessary, to prosecute
        those responsible for such activities.
      </p>
      <p>
        Please note that the examples provided above are illustrative and not
        intended to be exhaustive. For more details on how we use this
        information, please refer to the &quot;Use of Your Personal Data&quot;
        section.
      </p>
      <p>
        If We decide to collect additional categories of personal information or
        use the personal information We collected for materially different,
        unrelated, or incompatible purposes We will update this Privacy Policy.
      </p>
      <p>
        Disclosure of Personal Information for Business Purposes or Commercial
        Purposes
      </p>
      <p>
        We may use or disclose and may have used or disclosed in the last twelve
        (12) months the following categories of personal information for
        business or commercial purposes:
      </p>
      <p>Category A: Identifiers</p>
      <p>
        Category B: Personal information categories listed in the California
        Customer Records statute (Cal. Civ. Code &sect; 1798.80(e))
      </p>
      <p>Category F: Internet or other similar network activity</p>
      <p>
        Please note that the categories listed above are those defined in the
        CCPA. This does not mean that all examples of that category of personal
        information were in fact disclosed, but reflects our good faith belief
        to the best of our knowledge that some of that information from the
        applicable category may be and may have been disclosed.
      </p>
      <p>
        When We disclose personal information for a business purpose or a
        commercial purpose, We enter a contract that describes the purpose and
        requires the recipient to both keep that personal information
        confidential and not use it for any purpose except performing the
        contract.
      </p>
      <p>Sale of Personal Information</p>
      <p>
        As defined in the CCPA, &quot;sell&quot; and &quot;sale&quot; mean
        selling, renting, releasing, disclosing, disseminating, making
        available, transferring, or otherwise communicating orally, in writing,
        or by electronic or other means, a consumer&apos;s personal information
        by the business to a third party for valuable consideration. This means
        that We may have received some kind of benefit in return for sharing
        personal information, but not necessarily a monetary benefit.
      </p>
      <p>
        Please note that the categories listed below are those defined in the
        CCPA. This does not mean that all examples of that category of personal
        information were in fact sold, but reflects our good faith belief to the
        best of our knowledge that some of that information from the applicable
        category may be and may have been shared for value in return.
      </p>
      <p>
        We may sell and may have sold in the last twelve (12) months the
        following categories of personal information:
      </p>
      <p>Category A: Identifiers</p>
      <p>
        Category B: Personal information categories listed in the California
        Customer Records statute (Cal. Civ. Code &sect; 1798.80(e))
      </p>
      <p>Category F: Internet or other similar network activity</p>
      <p>Share of Personal Information</p>
      <p>
        We may share Your personal information identified in the above
        categories with the following categories of third parties:
      </p>
      <p>Service Providers</p>
      <p>Our affiliates</p>
      <h5>Our business partners</h5>
      <p>
        Third party vendors to whom You or Your agents authorize Us to disclose
        Your personal information in connection with products or services We
        provide to You
      </p>
      <p>Sale of Personal Information of Minors Under 16 Years of Age</p>
      <p>
        We do not sell the personal information of Consumers We actually know
        are less than 16 years of age, unless We receive affirmative
        authorization (the &quot;right to opt-in&quot;) from either the Consumer
        who is between 13 and 16 years of age, or the parent or guardian of a
        Consumer less than 13 years of age. Consumers who opt-in to the sale of
        personal information may opt-out of future sales at any time. To
        exercise the right to opt-out, You (or Your authorized representative)
        may submit a request to Us by contacting Us.
      </p>
      <p>
        If You have reason to believe that a child under the age of 13 (or 16)
        has provided Us with personal information, please contact Us with
        sufficient detail to enable Us to delete that information.
      </p>
      <h5>Your Rights under the CCPA</h5>
      <p>
        The CCPA provides California residents with specific rights regarding
        their personal information. If You are a resident of California, You
        have the following rights:
      </p>
      <p>
        The right to notice. You have the right to be notified which categories
        of Personal Data are being collected and the purposes for which the
        Personal Data is being used.
      </p>
      <p>
        The right to request. Under CCPA, You have the right to request that We
        disclose information to You about Our collection, use, sale, disclosure
        for business purposes and share of personal information. Once We receive
        and confirm Your request, We will disclose to You:
      </p>
      <p>The categories of personal information We collected about You</p>
      <p>
        The categories of sources for the personal information We collected
        about You
      </p>
      <p>
        Our business or commercial purpose for collecting or selling that
        personal information
      </p>
      <p>
        The categories of third parties with whom We share that personal
        information
      </p>
      <p>The specific pieces of personal information We collected about You</p>
      <p>
        If we sold Your personal information or disclosed Your personal
        information for a business purpose, We will disclose to You:
      </p>
      <p>The categories of personal information categories sold</p>
      <p>The categories of personal information categories disclosed</p>
      <p>
        The right to say no to the sale of Personal Data (opt-out). You have the
        right to direct Us to not sell Your personal information. To submit an
        opt-out request please contact Us.
      </p>
      <p>
        The right to delete Personal Data. You have the right to request the
        deletion of Your Personal Data, subject to certain exceptions. Once We
        receive and confirm Your request, We will delete (and direct Our Service
        Providers to delete) Your personal information from our records, unless
        an exception applies. We may deny Your deletion request if retaining the
        information is necessary for Us or Our Service Providers to:
      </p>
      <p>
        Complete the transaction for which We collected the personal
        information, provide a good or service that You requested, take actions
        reasonably anticipated within the context of our ongoing business
        relationship with You, or otherwise perform our contract with You.
      </p>
      <p>
        Detect security incidents, protect against malicious, deceptive,
        fraudulent, or illegal activity, or prosecute those responsible for such
        activities.
      </p>
      <p>
        Debug products to identify and repair errors that impair existing
        intended functionality.
      </p>
      <p>
        Exercise free speech, ensure the right of another consumer to exercise
        their free speech rights, or exercise another right provided for by law.
      </p>
      <p>
        Comply with the California Electronic Communications Privacy Act (Cal.
        Penal Code &sect; 1546 et. seq.).
      </p>
      <p>
        Engage in public or peer-reviewed scientific, historical, or statistical
        research in the public interest that adheres to all other applicable
        ethics and privacy laws, when the information&apos;s deletion may likely
        render impossible or seriously impair the research&apos;s achievement,
        if You previously provided informed consent.
      </p>
      <p>
        Enable solely internal uses that are reasonably aligned with consumer
        expectations based on Your relationship with Us.
      </p>
      <p>Comply with a legal obligation.</p>
      <p>
        Make other internal and lawful uses of that information that are
        compatible with the context in which You provided it.
      </p>
      <p>
        The right not to be discriminated against. You have the right not to be
        discriminated against for exercising any of Your consumer&apos;s rights,
        including by:
      </p>
      <p>Denying goods or services to You</p>
      <p>
        Charging different prices or rates for goods or services, including the
        use of discounts or other benefits or imposing penalties
      </p>
      <p>Providing a different level or quality of goods or services to You</p>
      <p>
        Suggesting that You will receive a different price or rate for goods or
        services or a different level or quality of goods or services
      </p>
      <p>Exercising Your CCPA Data Protection Rights</p>
      <p>
        In order to exercise any of Your rights under the CCPA, and if You are a
        California resident, You can contact Us:
      </p>
      <p>
        By email:{' '}
        <a
          href="https://averhealth.com/contact-averhealth/"
          target="_blank"
          rel="noreferrer"
        >
          https://averhealth.com/contact-averhealth/
        </a>
      </p>
      <p>
        By visiting this page on our website:{' '}
        <a
          href="https://averhealth.com/contact-averhealth/"
          target="_blank"
          rel="noreferrer"
        >
          https://averhealth.com/contact-averhealth/
        </a>
      </p>
      <p>By mail: 2916 W. Marshall St., Suite A Richmond, VA 23230</p>
      <p>
        Only You, or a person registered with the California Secretary of State
        that You authorize to act on Your behalf, may make a verifiable request
        related to Your personal information.
      </p>
      <p>Your request to Us must:</p>
      <p>
        Provide sufficient information that allows Us to reasonably verify You
        are the person about whom We collected personal information or an
        authorized representative
      </p>
      <p>
        Describe Your request with sufficient detail that allows Us to properly
        understand, evaluate, and respond to it
      </p>
      <p>
        We cannot respond to Your request or provide You with the required
        information if We cannot:
      </p>
      <p>Verify Your identity or authority to make the request</p>
      <p>And confirm that the personal information relates to You</p>
      <p>
        We will disclose and deliver the required information free of charge
        within 45 days of receiving Your verifiable request. The time period to
        provide the required information may be extended once by an additional
        45 days when reasonably necessary and with prior notice.
      </p>
      <p>
        Any disclosures We provide will only cover the 12-month period preceding
        the verifiable request&apos;s receipt.
      </p>
      <p>
        For data portability requests, We will select a format to provide Your
        personal information that is readily usable and should allow You to
        transmit the information from one entity to another entity without
        hindrance.
      </p>
      <p>Do Not Sell My Personal Information</p>
      <p>
        You have the right to opt-out of the sale of Your personal information.
        Once We receive and confirm a verifiable consumer request from You, we
        will stop selling Your personal information. To exercise Your right to
        opt-out, please contact Us.
      </p>
      <p>
        The Service Providers we partner with (for example, our analytics or
        advertising partners) may use technology on the Service that sells
        personal information as defined by the CCPA law. If you wish to opt out
        of the use of Your personal information for interest-based advertising
        purposes and these potential sales as defined under CCPA law, you may do
        so by following the instructions below.
      </p>
      <p>
        Please note that any opt out is specific to the browser You use. You may
        need to opt out on every browser that You use.
      </p>
      <p>Website</p>
      <p>
        You can opt out of receiving ads that are personalized as served by our
        Service Providers by following our instructions presented on the
        Service:
      </p>
      <p>
        The NAI&apos;s opt-out platform:{' '}
        <a
          href="http://www.networkadvertising.org/choices/"
          target="_blank"
          rel="noreferrer"
        >
          http://www.networkadvertising.org/choices/
        </a>
      </p>
      <p>
        The EDAA&apos;s opt-out platform{' '}
        <a
          href="http://www.youronlinechoices.com/"
          target="_blank"
          rel="noreferrer"
        >
          http://www.youronlinechoices.com/
        </a>
      </p>
      <p>
        The DAA&apos;s opt-out platform:{' '}
        <a
          href="http://optout.aboutads.info/?c=2&amp;lang=EN"
          target="_blank"
          rel="noreferrer"
        >
          http://optout.aboutads.info/?c=2&amp;lang=EN
        </a>
      </p>
      <p>
        The opt out will place a cookie on Your computer that is unique to the
        browser You use to opt out. If you change browsers or delete the cookies
        saved by your browser, You will need to opt out again.
      </p>
      <h5>Mobile Devices</h5>
      <p>
        Your mobile device may give You the ability to opt out of the use of
        information about the apps You use in order to serve You ads that are
        targeted to Your interests:
      </p>
      <p>
        &quot;Opt out of Interest-Based Ads&quot; or &quot;Opt out of Ads
        Personalization&quot; on Android devices
      </p>
      <p>&quot;Limit Ad Tracking&quot; on iOS devices</p>
      <p>
        You can also stop the collection of location information from Your
        mobile device by changing the preferences on Your mobile device.
      </p>
      <p>
        &quot;Do Not Track&quot; Policy as Required by California Online Privacy
        Protection Act (CalOPPA)
      </p>
      <p>Our Service does not respond to Do Not Track signals.</p>
      <p>
        However, some third party websites do keep track of Your browsing
        activities. If You are visiting such websites, You can set Your
        preferences in Your web browser to inform websites that You do not want
        to be tracked. You can enable or disable DNT by visiting the preferences
        or settings page of Your web browser.
      </p>
      <h5>Children&apos;s Privacy</h5>
      <p>
        The Service may contain content appropriate for children under the age
        of 13. As a parent, you should know that through the Service children
        under the age of 13 may participate in activities that involve the
        collection or use of personal information. We use reasonable efforts to
        ensure that before we collect any personal information from a child, the
        child&apos;s parent receives notice of and consents to our personal
        information practices.
      </p>
      <p>
        We also may limit how We collect, use, and store some of the information
        of Users between 13 and 18 years old. In some cases, this means We will
        be unable to provide certain functionality of the Service to these
        Users. If We need to rely on consent as a legal basis for processing
        Your information and Your country requires consent from a parent, We may
        require Your parent&apos;s consent before We collect and use that
        information.
      </p>
      <p>
        We may ask a User to verify its date of birth before collecting any
        personal information from them. If the User is under the age of 13, the
        Service will be either blocked or redirected to a parental consent
        process.
      </p>
      <p>Information Collected from Children Under the Age of 13</p>
      <p>
        The Company may collect and store persistent identifiers such as cookies
        or IP addresses from Children without parental consent for the purpose
        of supporting the internal operation of the Service.
      </p>
      <p>
        We may collect and store other personal information about children if
        this information is submitted by a child with prior parent consent or by
        the parent or guardian of the child.
      </p>
      <p>
        The Company may collect and store the following types of personal
        information about a child when submitted by a child with prior parental
        consent or by the parent or guardian of the child:
      </p>
      <p>First and/or last name</p>
      <p>Date of birth</p>
      <p>Gender</p>
      <p>Telephone number</p>
      <p>
        For further details on the information We might collect, You can refer
        to the &quot;Types of Data Collected&quot; section of this Privacy
        Policy. We follow our standard Privacy Policy for the disclosure of
        personal information collected from and about children.
      </p>
      <p>Parental Access</p>
      <p>
        A parent who has already given the Company permission to collect and use
        his child personal information can, at any time:
      </p>
      <p>Review, correct or delete the child&apos;s personal information</p>
      <p>
        Discontinue further collection or use of the child&apos;s personal
        information
      </p>
      <p>
        To make such a request, You can write to Us using the contact
        information provided in this Privacy Policy.
      </p>
      <p>
        Your California Privacy Rights (California&apos;s Shine the Light law)
      </p>
      <p>
        Under California Civil Code Section 1798 (California&apos;s Shine the
        Light law), California residents with an established business
        relationship with us can request information once a year about sharing
        their Personal Data with third parties for the third parties&apos;
        direct marketing purposes.
      </p>
      <p>
        If you&apos;d like to request more information under the California
        Shine the Light law, and if You are a California resident, You can
        contact Us using the contact information provided below.
      </p>
      <p>
        California Privacy Rights for Minor Users (California Business and
        Professions Code Section 22581)
      </p>
      <p>
        California Business and Professions Code Section 22581 allows California
        residents under the age of 18 who are registered users of online sites,
        services or applications to request and obtain removal of content or
        information they have publicly posted.
      </p>
      <p>
        To request removal of such data, and if You are a California resident,
        You can contact Us using the contact information provided below, and
        include the email address associated with Your account.
      </p>
      <p>
        Be aware that Your request does not guarantee complete or comprehensive
        removal of content or information posted online and that the law may not
        permit or require removal in certain circumstances.
      </p>
      <h5>Links to Other Websites</h5>
      <p>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party&apos;s site. We strongly advise You to review the Privacy
        Policy of every site You visit.
      </p>
      <p>
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <h5>Changes to this Privacy Policy</h5>
      <p>
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p>
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the &quot;Last
        updated&quot; date at the top of this Privacy Policy.
      </p>
      <p>
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <p>
        Further information on privacy practices can be found at{' '}
        <a
          href="https://averhealth.com/wp-content/uploads/Notice-of-Privacy-Practices.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://averhealth.com/wp-content/uploads/Notice-of-Privacy-Practices.pdf
        </a>
      </p>
      <h5>Contact Us</h5>
      <p>
        If you have any questions about this Privacy Policy, You can contact us:
      </p>
      <p>
        By visiting this page on our website:{' '}
        <a
          href="https://averhealth.com/contact-averhealth/"
          target="_blank"
          rel="noreferrer"
        >
          https://averhealth.com/contact-averhealth/
        </a>
      </p>
      <p>By mail: 2916 W. Marshall St., Suite A Richmond, VA 23230</p>
    </div>
    <div className={styles.modalFooter}>
      <button
        type="button"
        onClick={() => setShowModal(false)}
        className={styles.backButton}
      >
        Back
      </button>
    </div>
  </div>
);
TnCstaticContent.propTypes = {
  setShowModal: PropTypes.func,
};

TnCstaticContent.defaultProps = {
  setShowModal: () => {},
};
