import { useContext, useState, useEffect } from 'react';
import { FcLock } from 'react-icons/fc';
import { useHistory } from 'react-router-dom';
import { cookieConstants } from '../../../constants/app-constants';
import { RootContext } from '../../../context/RootContext';
import { Modal } from '../Modal/Modal';
import styles from './TimeoutModal.module.scss';

export const TimeoutModal = () => {
  const { timeoutValue } = useContext(RootContext);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setShowModal(timeoutValue === cookieConstants.COOKIE_ADDON_TIME);
  }, [timeoutValue]);

  const onClosePress = () => {
    history.replace('/');
    setShowModal(false);
  };

  if (showModal) {
    return (
      <div className="timeout-modal">
        <Modal>
          <div className={styles.TimeoutModelContents}>
            <FcLock size={55} />
            <h3 className={styles.modelContentTitle}>Locked Out</h3>
            <div className={styles.modelContentData}>
              <p>
                You have been locked out of the app for the next 10 minutes as
                you have made 5 unsuccessful attempts to register.
              </p>
              <button
                className={styles.modalCloseButton}
                type="button"
                onClick={onClosePress}
              >
                Back
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
  return null;
};

TimeoutModal.propTypes = {};

TimeoutModal.defaultProps = {};
