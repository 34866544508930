import { createContext } from 'react';

export const RootContext = createContext({
  timeoutValue: 0,
  showLoader: false,
  isLoggedIn: false,
  setIsLoggedIn: () => { },
  setShowLoader: () => { },
  setTimeoutValue: () => { },
});
