import { FcLock } from 'react-icons/fc';
import styles from './AuthRequired.module.scss';

export const AuthRequired = () => (
    <div className="contentWrapper">
        <div className="enrollmentTextTitle">Authorization Required</div>
        <div className={styles.authorizationRequiredIcon}>
            <FcLock />
        </div>

        <div className="enrollmentTextContainer">
            Your program is not currently set up to receive SMS notifications. Please contact the person who requires you to test to ask to be set-up for SMS notifications.
        </div>

        <div className="logoContainer">
            <img
                src="https://averhealth.com/wp-content/uploads/2017/11/full-logo.png"
                className="appLogo"
                alt="logo"
            />
        </div>
    </div>
);
