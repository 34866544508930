/* eslint-disable no-alert */
import {
  useState, useCallback, useEffect, useContext
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import OtpInput from 'react-otp-input';
import base64 from 'base-64';
import utf8 from 'utf8';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';
import {
  API_ENDPOINT,
  API_CONSTANTS,
  BASE_URL,
} from '../../constants/api-endpoint';
import appConstants, { cookieConstants } from '../../constants/app-constants';
import styles from './VerifyCode.module.scss';
import { isDebug } from '../../utils/common-methods';
import { sendCodeForEnrollment, verifyCodeAPI } from '../../utils/api-provider';
import { RootContext } from '../../context/RootContext';

const TIMER_IN_SECONDS = 60;

const { VERIFY_STATUS_CODES } = appConstants;

const convertSecToClockFormat = (timeInSec) => {
  const minutes = Math.floor(timeInSec / 60);
  const seconds = timeInSec - minutes * 60;
  return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds
    }`;
};

export const VerifyCode = ({ numberOfDigits, onlyNumbers, placeholder }) => {
  const [otp, setOtp] = useState('');
  const [timer, setTimer] = useState(0);
  const [sendNewCode, setSendNewCode] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const donorData = location?.state?.params;
  const { setTimeoutValue, setShowLoader } = useContext(RootContext);

  useEffect(() => {
    let interval;
    if (sendNewCode) {
      if (timer < TIMER_IN_SECONDS) {
        interval = setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
        }, 1000);
      } else {
        setSendNewCode(false);
        clearInterval(interval);
      }
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timer, sendNewCode]);

  const updateCountCookie = () => {
    const currentUnSuccessfulCount = Cookies.get(cookieConstants.UNSUCCESSFUL_COUNT) || 1;
    const newCount = parseInt(currentUnSuccessfulCount, 10) + 1;
    const expires = new Date(
      new Date().getTime() + cookieConstants.COOKIE_ADDON_TIME
    );
    if (newCount > cookieConstants.MIN_UNSUCCESSFUL_COUNT) {
      Cookies.remove(cookieConstants.UNSUCCESSFUL_COUNT);
      Cookies.set(cookieConstants.UNSUCCESSFUL_COUNT, `${newCount}`, {
        expires,
      });
      Cookies.set(
        cookieConstants.UNSUCCESSFUL_COUNT_EXPIRY_TIME,
        `${new Date(expires).getTime()}`,
        { expires }
      );
      setTimeoutValue(cookieConstants.COOKIE_ADDON_TIME);
    } else {
      Cookies.set(cookieConstants.UNSUCCESSFUL_COUNT, `${newCount}`, { expires });
    }
  };

  const verifyCode = useCallback(async () => {
    try {
      setShowLoader(true);
      const verifyReponse = await verifyCodeAPI({ donorId: donorData.id, otp });
      if (verifyReponse.status === 200) {
        Cookies.remove(cookieConstants.UNSUCCESSFUL_COUNT);
        Cookies.remove(cookieConstants.UNSUCCESSFUL_COUNT_EXPIRY_TIME);
        try {
          const queryString = `Pin=${encodeURIComponent(donorData.pin)}
                    &Birthdate=${encodeURIComponent(
            donorData.dob
          )}&PhoneNumber=${encodeURIComponent(donorData.cell)}`;
          const url = `${isDebug() ? API_CONSTANTS.WEB_API : BASE_URL.WEB_API}${API_ENDPOINT.ENROLL
            }?${queryString}`;

          const string = `${process.env.REACT_APP_SMS_USERNAME}:${process.env.REACT_APP_SMS_PASSWORD}`;
          const bytes = utf8.encode(string);
          const credidential = base64.encode(bytes);
          const headers = {
            Authorization: `Basic ${credidential}`,
            'Content-Type': 'application/json',
          };
          const response = await fetch(url, {
            method: 'POST',
            headers: headers,
          });
          setShowLoader(false);
          if (response.status !== 200) {
            let errorMsg = '';
            if (
              !process.env.REACT_APP_SMS_USERNAME
              || !process.env.REACT_APP_SMS_PASSWORD
            ) {
              alert(
                'Server side error: 500, Sorry for inconvenice, please try later.'
              );
              errorMsg = `REACT_APP variable is ${process.env.REACT_APP_SMS_USERNAME} `;
            }
            history.replace('/');
            throw new Error(
              `Status: ${response.status}, ${response.statusText} ${errorMsg}`
            );
          }
          const result = await response.json();
          switch (result.Code) {
            case VERIFY_STATUS_CODES.ENROLLED_2000:
              history.replace('/enrolled');
              break;
            case VERIFY_STATUS_CODES.CANNOT_ENROLL_2001:
              history.replace('/cannot-enroll');
              break;
            case VERIFY_STATUS_CODES.EXIST_2002:
              history.replace('/exist');
              break;
            case VERIFY_STATUS_CODES.FAILED_5001:
              history.replace('/failed');
              break;
            default:
              history.replace('/');
              throw new Error(
                `Status: ${response.status}, ${response.statusText}`
              );
          }
        } catch (error) {
          setShowLoader(false);
          console.log(error);
          toast('Something went wrong');
        }
      } else {
        toast('OTP does not match');
      }
      setShowLoader(false);
    } catch {
      setShowLoader(false);
      updateCountCookie();
      alert('OTP does not match. Please check and try again.');
      // Error handling
    }
    setShowLoader(false);
  }, [otp]);

  const sendNewCodeClick = () => {
    setShowLoader(true);
    sendCodeForEnrollment({ donorId: donorData.id, cell: donorData.cell })
      .then((res) => {
        setShowLoader(false);
        if (res.status === 200) {
          setOtp('');
          setSendNewCode(true);
          setTimer(0);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        console.log(error);
        toast('Something went wrong');
      });
  };

  return (
    <div className="contentWrapper">
      <div className="enrollmentTextTitle">Please Verify Your Number</div>
      <div className="enrollmentTextContainer">
        A six-digit code was sent to the number you provided. Please enter it
        here to complete your enrollment.
      </div>
      <div className={styles.otpVerifyBoxWrap}>
        <OtpInput
          placeholder={placeholder}
          value={otp}
          onChange={setOtp}
          numInputs={numberOfDigits}
          isInputNum={onlyNumbers}
          inputStyle={{
            width: '2rem',
            height: '2.5rem',
            margin: '0.2rem 0.2rem',
            fontSize: '2rem',
            borderRadius: 4,
            border: '1px solid rgba(0,0,0,0.3)',
          }}
        />
      </div>
      <div className={styles.didntGetCodeBox}>
        {sendNewCode && (
          <div>{convertSecToClockFormat(TIMER_IN_SECONDS - timer)}</div>
        )}
        <button
          disabled={sendNewCode}
          type="button"
          onClick={sendNewCodeClick}
          className={styles.didntGetCode}
        >
          Send me a new code
        </button>
      </div>
      <button
        type="button"
        disabled={otp.length !== numberOfDigits}
        className={styles.submitTwoFactor}
        onClick={verifyCode}
      >
        Submit
      </button>
    </div>
  );
};

VerifyCode.propTypes = {
  numberOfDigits: PropTypes.number,
  onlyNumbers: PropTypes.bool,
  placeholder: PropTypes.string,
};

VerifyCode.defaultProps = {
  numberOfDigits: 6,
  onlyNumbers: true,
  placeholder: '------',
};
