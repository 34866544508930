const appConstants = {
  AVERHEALTH_SSO_AUTH: 'AVERHEALTH_SSO_AUTH',
  VERIFY_STATUS_CODES: {
    ENROLLED_2000: 2000,
    CANNOT_ENROLL_2001: 2001,
    EXIST_2002: 2002,
    FAILED_5001: 5001,
  },
  ENVIRONMENT: 'PRODUCTION',
};

export const cookieConstants = {
  // For Timeout
  UNSUCCESSFUL_COUNT: 'UNSUCCESSFUL_COUNT',
  UNSUCCESSFUL_COUNT_EXPIRY_TIME: 'UNSUCCESSFUL_COUNT_EXPIRY_TIME',
  COOKIE_ADDON_TIME: 10 * 60 * 1000, // 10 minutes
  MIN_UNSUCCESSFUL_COUNT: 5,

  // For logged in or not
  IS_LOGGED_IN: 'IS_LOGGED_IN'
};

export default appConstants;
