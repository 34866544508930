import { useHistory } from 'react-router-dom';

import { FcInspection } from 'react-icons/fc';
import styles from './AlreadyEnrolled.module.scss';

export const AlreadyEnrolled = () => {
    const history = useHistory();
    return (
        <div className="contentWrapper">
            <div className="enrollmentTextTitle">You are already enrolled!</div>
            <div className={styles.failIcon}>
                <FcInspection />
            </div>
            <div className="enrollmentTextContainer">
                Our records show that you have already registered.
            </div>

            <div className="logoContainer">
                <button type="button" onClick={() => history.replace('/')} className={styles.submitTwoFactor}>Back</button>
            </div>
        </div >
    );
};
